import {Hit} from '@algolia/client-search'
import {TrainingSubscriptionAlgolia} from '../types/algolia'

/**
 * Formats a date into a localized month string or full date string.
 * @param {Date} date - The date object to format.
 * @param {string} locale - The locale code for localization (e.g., 'en-GB').
 * @param {boolean} includeDay - Whether to include the day in the formatted string.
 * @returns {string} Localized month name or full date string based on parameters.
 */
export const formatDate = (
  date: Date,
  locale: string,
  includeDay: boolean = false,
): string => {
  if (includeDay) {
    return date.toLocaleDateString(locale, {
      day: 'numeric',
      month: 'long',
    })
  } else {
    return date.toLocaleString(locale, {month: 'long'})
  }
}

/**
 * Converts a date to a Unix timestamp.
 * @param {Date} date- The date to convert.
 * @returns {number} Unix timestamp (seconds).
 */
export const dateToTimestamp = (date: Date): number => {
  return date.getTime() / 1000
}

/**
 * Gets the Unix timestamp of the last day of the month for a given date.
 * @param {Date} date - The date for which to find the end of the month.
 * @returns {number} Unix timestamp of the end of the month.
 */
export const getEndOfMonth = (date: Date): number => {
  return dateToTimestamp(new Date(date.getFullYear(), date.getMonth() + 1, 0))
}

/**
 * Removes the locale prefix from a URL path.
 * @param {string} urlPath - The URL path containing the locale prefix.
 * @param {string} locale - The current locale obtained from router.locale.
 * @returns {string} URL path without the locale prefix.
 */
export const removeLocaleFromPath = (
  urlPath: string,
  locale: string,
): string => {
  if (locale && urlPath.startsWith(`/${locale}/`)) {
    return urlPath.replace(`/${locale}`, '')
  }
  return urlPath
}

/**
 * Generates a filter query string based on category IDs.
 * @param {string[]} items - Array of category IDs.
 * @returns {string}  Filter query string.
 */
export const categoryFilterId = (items: string[]): string => {
  if (!items || items.length === 0) {
    return ''
  }
  const categoryIds = items.map(item => `field_category_id:${item}`)
  return `AND ${categoryIds.join(' OR ')}`
}

/**
 * Constructs a date range query string for training session start dates.
 * @param {Date} startDate - Start date of the range.
 * @param {Date} endDate - End date of the range.
 * @returns {string} Constructed date range query string.
 */
export const constructDateRangeQuery = (
  startDate: Date,
  endDate: Date,
): string => {
  const startTimestamp = dateToTimestamp(startDate)
  const endTimestamp = dateToTimestamp(endDate)
  return `AND training_session_start_date:${startTimestamp} TO ${endTimestamp}`
}

/**
 * Expands hits by duplicating them for each date in `training_session_start_date_list`
 *
 * @param {Hit<TrainingSubscriptionAlgolia>[]} hits - Array of hits to expand.
 * @returns {Hit<TrainingSubscriptionAlgolia>[]} Array of hits with expanded dates.
 */
export const expandHitsByDateList = (
  hits: Hit<TrainingSubscriptionAlgolia>[],
): Hit<TrainingSubscriptionAlgolia>[] => {
  return hits.reduce((acc, hit) => {
    if (Array.isArray(hit.training_session_start_date_list)) {
      hit.training_session_start_date_list.forEach((date, index) => {
        if (index === 0) {
          acc.push(hit)
        } else {
          const copy = {...hit, training_session_start_date: date}
          acc.push(copy)
        }
      })
    } else {
      acc.push(hit)
    }
    return acc
  }, [] as Hit<TrainingSubscriptionAlgolia>[])
}

/**
 * Filters hits based on `training_session_start_date` within the specified month range.
 * @param {Hit<TrainingSubscriptionAlgolia>[]} hits - Array of hits to filter.
 * @param {Date | number} startOfMonth - Start date of the month range.
 * @param {Date | number} endOfMonth - End date of the month range.
 * @returns {Hit<TrainingSubscriptionAlgolia>[]} Filtered array of hits.
 */
export const filterHitsByMonth = (
  hits: Hit<TrainingSubscriptionAlgolia>[],
  startOfMonth: Date | number,
  endOfMonth: Date | number,
): Hit<TrainingSubscriptionAlgolia>[] => {
  return hits.filter(
    hit =>
      hit.training_session_start_date >= startOfMonth &&
      hit.training_session_start_date <= endOfMonth,
  )
}

/**
 * Sorts hits based on `training_session_start_date` in ascending order.
 * @param {Hit<TrainingSubscriptionAlgolia>[]} hits - Array of hits to sort.
 * @returns {Hit<TrainingSubscriptionAlgolia>[]} Sorted array of hits.
 */
export const sortHitsByStartDate = (
  hits: Hit<TrainingSubscriptionAlgolia>[],
): Hit<TrainingSubscriptionAlgolia>[] => {
  return hits
    .slice()
    .sort(
      (a, b) =>
        (a.training_session_start_date as number) -
        (b.training_session_start_date as number),
    )
}

/**
 * Converts a Unix timestamp to a Date object and returns the day of the month.
 *
 * @param {number} timestamp - The Unix timestamp (in seconds).
 * @returns {number} The day of the month (1-31).
 */
export const getDate = (timestamp: number) => {
  let date = new Date(timestamp * 1000)
  return date.getDate()
}

/**
 * Groups hits by their `training_session_start_date` and returns the grouped sessions.
 * @param {Hit<TrainingSubscriptionAlgolia>[]} hits - Array of hits to be grouped and sorted.
 * @returns { { [startDate: string]: Hit<TrainingSubscriptionAlgolia>[] } } Object with hits grouped by start date.
 */
export const groupHitsByStartDate = (
  hits: Hit<TrainingSubscriptionAlgolia>[],
): {[startDate: string]: Hit<TrainingSubscriptionAlgolia>[]} => {
  const groupedSessions: {
    [startDate: string]: Hit<TrainingSubscriptionAlgolia>[]
  } = {}

  hits.forEach(hit => {
    const startDate = getDate(hit.training_session_start_date as number)

    if (!groupedSessions[startDate]) {
      groupedSessions[startDate] = []
    }
    groupedSessions[startDate].push(hit)
  })

  return groupedSessions
}
