import {useRouter} from 'next/router'
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useRef,
} from 'react'
import {
  dateToTimestamp,
  getEndOfMonth,
  formatDate,
} from '../calendar-block-logic'

interface CalendarContextType {
  monthTitle: string | null
  startOfMonth: number | Date | null
  endOfMonth: number | null
  setMonthTitle: (title: string | null) => void
  setStartOfMonth: (date: number | null) => void
  setEndOfMonth: (date: number | null) => void
  startOfInitialMonth: undefined | Date
  endOfRange: undefined | Date
  currentDate: undefined | React.MutableRefObject<Date>
}

const CalendarContext = createContext<CalendarContextType>({
  monthTitle: null,
  startOfMonth: null,
  endOfMonth: null,
  setMonthTitle: () => {},
  setStartOfMonth: () => {},
  setEndOfMonth: () => {},
  startOfInitialMonth: undefined,
  endOfRange: undefined,
  currentDate: undefined,
})

interface CalendarProviderProps {
  children: ReactNode
}

export function CalendarProvider({children}: CalendarProviderProps) {
  const router = useRouter()
  let currentDate: React.MutableRefObject<Date> = useRef(new Date())
  const monthEndDate = getEndOfMonth(currentDate.current)
  const currentMonth = currentDate.current.getMonth()
  const currentYear = currentDate.current.getFullYear()

  const startOfInitialMonth = new Date(currentYear, currentMonth, 1)
  const endOfRange = new Date(currentYear, currentMonth + 7, 0) // Last day of the month 7 months from now

  const [monthTitle, setMonthTitle] = useState<string | null>(
    formatDate(currentDate.current, router.locale!),
  )
  const [startOfMonth, setStartOfMonth] = useState<number | Date | null>(
    dateToTimestamp(currentDate.current),
  )
  const [endOfMonth, setEndOfMonth] = useState<number | null>(monthEndDate)

  return (
    <CalendarContext.Provider
      value={{
        monthTitle,
        startOfMonth,
        endOfMonth,
        setMonthTitle,
        setStartOfMonth,
        setEndOfMonth,
        startOfInitialMonth,
        endOfRange,
        currentDate,
      }}
    >
      {children}
    </CalendarContext.Provider>
  )
}

export default function useCalendar(): CalendarContextType {
  return useContext(CalendarContext)
}
