import React from 'react'

export type DataContextType = {
  isInterestedDialogOpen?: boolean
  setIsInterestedDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
}

// eslint-disable-next-line import/prefer-default-export
export const DataContext = React.createContext<DataContextType>({
  isInterestedDialogOpen: false,
  setIsInterestedDialogOpen: () => {},
})
