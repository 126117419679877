import '../styles/main.scss'

import React, {useCallback, useEffect} from 'react'
import type {AppProps} from 'next/app'
import Head from 'next/head'
import {appWithTranslation} from 'next-i18next'
import {useRouter} from 'next/router'
import Script from 'next/script'
import {QueryClient, QueryClientProvider} from 'react-query'
import {NextAdapter} from 'next-query-params'
import {QueryParamProvider} from 'use-query-params'
import {DataContext} from '../utils/data-context'
import type {SlugProps} from './[[...slug]]'
import {Error404Props} from './404'
import {BookingProps} from './boeking/[[...slug]]'
import {BookingConfirmationProps} from './boeking/bevestiging'
import {BookingSubscriptionConfirmationProps} from './boeking/bevestiging-abo'
import {GTMProvider} from '../utils/hooks/useGTM'
import {Roboto} from 'next/font/google'
import {CalendarProvider} from '../utils/hooks/useCalendar'

const roboto = Roboto({
  subsets: [
    'latin',
    'latin-ext',
    'cyrillic',
    'cyrillic-ext',
    'greek',
    'greek-ext',
  ],
  weight: ['400', '500', '700'],
})

declare const window: any

function MyApp({
  Component,
  pageProps,
}: AppProps<
  | SlugProps
  | Error404Props
  | BookingProps
  | BookingConfirmationProps
  | BookingSubscriptionConfirmationProps
>) {
  const [queryClient] = React.useState(() => new QueryClient())
  const [isInterestedDialogOpen, setIsInterestedDialogOpen] =
    React.useState(false)
  const path = React.useRef<string>('')
  const router = useRouter()
  const pushPageViewData = useCallback((site: any, page: any) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({event: 'virtualPageview', site, page})
  }, [])

  let retryCount = 0
  const maxRetries = 3

  const reloadOTBanner = useCallback(() => {
    if (
      window.OneTrust != null &&
      typeof window.OneTrust !== 'undefined' &&
      typeof window.OneTrust.Init === 'function'
    ) {
      window.OneTrust.Init()
      setTimeout(() => {
        window.OneTrust.LoadBanner()
        const toggleDisplay = document.getElementsByClassName(
          'ot-sdk-show-settings',
        )
        for (let i = 0; i < toggleDisplay.length; i += 1) {
          toggleDisplay[i].addEventListener('click', event => {
            event.stopImmediatePropagation()
            window.OneTrust.ToggleInfoDisplay()
          })
        }
      }, 1000)
    } else {
      retryCount++
      if (retryCount < maxRetries) {
        console.warn('OneTrust is not available yet. Retrying...')
        setTimeout(reloadOTBanner, 500) // Retry after 500ms
      } else {
        console.error('Max retries reached. OneTrust is not available.')
      }
    }
  }, [retryCount])

  const handleRouteChange = useCallback(() => {
    if (path.current !== router.asPath) {
      window.dataLayer = window.dataLayer || []
      const site = {
        environment:
          pageProps?.environment === 'prd' ? 'production' : 'staging',
        country:
          pageProps?.page?.attributes?.langcode.split('-')?.[1] ??
          router.locale?.split('-')?.[1],
        language:
          pageProps?.page?.attributes?.langcode.split('-')?.[0] ??
          router.locale?.split('-')?.[0],
      }
      const page = {
        contentType: pageProps?.page?.type
          .replace('node--', '')
          .replace('_', ' '),
      }
      pushPageViewData(site, page)
      path.current = router.asPath
    }

    reloadOTBanner()
  }, [
    pageProps?.environment,
    pageProps?.page?.attributes?.langcode,
    pageProps?.page?.type,
    pushPageViewData,
    reloadOTBanner,
    router.asPath,
    router.locale,
  ])

  useEffect(() => {
    handleRouteChange()
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [handleRouteChange, router])

  return (
    <main className={roboto.className}>
      <DataContext.Provider
        value={{
          isInterestedDialogOpen,
          setIsInterestedDialogOpen,
        }}
      >
        <QueryClientProvider client={queryClient}>
          <QueryParamProvider adapter={NextAdapter}>
            <Head>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />
            </Head>
            <Script
              id="gtm"
              dangerouslySetInnerHTML={{
                __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-T9PCCLN');`,
              }}
              strategy="afterInteractive"
            />
            <GTMProvider>
              <CalendarProvider>
                <Component {...pageProps} />
              </CalendarProvider>
            </GTMProvider>
          </QueryParamProvider>
        </QueryClientProvider>
      </DataContext.Provider>
    </main>
  )
}

export default appWithTranslation<AppProps>(MyApp)
