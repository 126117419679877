import {ReactNode, createContext, useContext, useEffect, useState} from 'react'

type Props = {
  children: ReactNode
}

declare const window: any

const GTMContext = createContext({
  isLoaded: false,
})

let interval: NodeJS.Timer

function GTMProvider({children}: Props) {
  const [isLoaded, setIsLoaded] = useState(false)
  useEffect(() => {
    if (!isLoaded) {
      clearInterval(interval)
      interval = setInterval(() => {
        if (
          typeof window !== 'undefined' &&
          typeof window.gtag === 'function'
        ) {
          clearInterval(interval)
          setIsLoaded(true)
        }
      }, 500)
    }

    return () => clearInterval(interval)
  }, [isLoaded])

  return (
    <GTMContext.Provider
      value={{
        isLoaded,
      }}
    >
      {children}{' '}
    </GTMContext.Provider>
  )
}

function useGTM() {
  return useContext(GTMContext)
}

export {GTMProvider}
export default useGTM
